import { createAction } from 'redux-actions';

import api from 'lib/api';
import { curationContainsItemsBySource } from 'lib/curationUtils';
import some from 'lodash.some';
import {
  applyServerPagingAndFilters,
  checkIfWaffleShouldBeModified,
} from 'lib/waffleUtils';

// Types
export const FRONT_LOAD_PENDING = 'front/FRONT_LOAD_PENDING';
export const FRONT_LOAD_COMPLETE = 'front/FRONT_LOAD_COMPLETE';
export const FRONT_LOAD_FAILED = 'front/FRONT_LOAD_FAILED';
export const SET_DRAFT_ID = 'front/SET_DRAFT_ID';
export const SET_PAID_CONTENT_POSITION = 'front/SET_PAID_CONTENT_POSITION';
export const SET_HAS_NO_PAID_CONTENT = 'front/SET_HAS_NO_PAID_CONTENT';

export const LOCAL_WEATHER_PENDING = 'localnews/LOCAL_WEATHER_PENDING';
export const LOCAL_WEATHER_SUCCESS = 'localnews/LOCAL_WEATHER_SUCCESS';
export const LOCAL_WEATHER_ERROR = 'localnews/LOCAL_WEATHER_ERROR';

export const FRONT_NOT_FOUND_ACTION = createAction(FRONT_LOAD_FAILED)({ status: 404 });

const sectionValidationRegex = /^[/a-zA-Z0-9_-]+$/;

const loadFrontFailedAs404Thunk = (dispatch) => dispatch(FRONT_NOT_FOUND_ACTION);

function validateFrontParams(section) {
  if (section && !sectionValidationRegex.test(section)) {
    return loadFrontFailedAs404Thunk;
  }

  return null;
}

// Actions
export const loadFront = (vertical, section, query) => api({
  endpoint: `curation/slug/${vertical}${section ? `/${section}` : ''}`,
  types: [FRONT_LOAD_PENDING, FRONT_LOAD_COMPLETE, FRONT_LOAD_FAILED],
  failedValidationCallback: validateFrontParams(section),
  modifyResponse: async (json) => checkIfWaffleShouldBeModified(
    vertical,
    section,
    query,
    json,
    applyServerPagingAndFilters,
  ),
});

export const loadFrontDraft = (draftId) => api({
  endpoint: `curation/draft/${draftId}`,
  types: [FRONT_LOAD_PENDING, FRONT_LOAD_COMPLETE, FRONT_LOAD_FAILED],
});

export const setDraftId = (draftId) => ({
  type: SET_DRAFT_ID,
  payload: {
    draftId,
  },
});

export const setPaidContentPosition = (paidContentPosition) => ({
  type: SET_PAID_CONTENT_POSITION,
  payload: {
    paidContentPosition,
  },
});

export const hasNoPaidContent = () => ({
  type: SET_HAS_NO_PAID_CONTENT,
});


// Reducer
const INITIAL_STATE = {
  loading: false,
  draftId: null,
  curation: {},
  error: null,
  paidContentPosition: null,
  hasNoPaidContent: false,
  isEmbedProgramGuide: false,
  weather: {
    error: null,
    hours: [],
    loading: false,
  },
};

export function front(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FRONT_LOAD_PENDING:
      return {
        ...state,
        loading: true,
      };

    case FRONT_LOAD_COMPLETE: {
      const { curation } = action.payload.data;

      // Filter packages type embed with program guide for https://master.d2gnmmimbvzgnk.amplifyapp.com/?vertical=true
      const isEmbedProgramGuide = some(curation.layouts, {
        packages: [{
          type: 'embed',
          items: [{
            computedValues: {
              url: 'https://master.d2gnmmimbvzgnk.amplifyapp.com/?vertical=true',
            },
          }],
        }],
      });

      return {
        ...state,
        curation,
        loading: false,
        stackCommerceFront: curationContainsItemsBySource(curation, 'StackCommerce'),
        isEmbedProgramGuide,
      };
    }

    case SET_DRAFT_ID:
      return {
        ...state,
        draftId: action.payload.draftId,
      };

    case FRONT_LOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_PAID_CONTENT_POSITION:
      if (state.paidContentPosition !== null) return state;

      return {
        ...state,
        paidContentPosition: action.payload.paidContentPosition,
      };

    case SET_HAS_NO_PAID_CONTENT:
      return {
        ...state,
        hasNoPaidContent: true,
      };

    case LOCAL_WEATHER_PENDING:
      return {
        ...state,
        weather: {
          ...state.weather,
          loading: true,
        },
      };

    case LOCAL_WEATHER_SUCCESS:
      return {
        ...state,
        weather: {
          ...action.payload,
          loading: false,
        },
      };

    case LOCAL_WEATHER_ERROR:
      return {
        ...state,
        weather: {
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
}
