/**
 * Sends a POST request to the specified URL.
 *
 * @param {string} url - The URL to fetch from.
 * @param {Object} [options={}] - The options for the request.
 * @param {string} [options.mparticleId] - The mParticle ID. If not provided, it will be fetched from the window.mParticle object.
 * @returns {Promise<Object>} The response data as a JavaScript object. Throws an error if the network response was not ok or if the fetch operation fails.
 */
export function api(url, { mparticleId } = {}) {
  const options = {
    method: 'post',
    headers: {
      'X-Auth-Token': window.HFSapi?.identity.token || null,
      'Content-Type': 'application/json',
    },
    credentials: 'omit',
    body: JSON.stringify({
      customerId: window.HFSapi?.identity.user.id || null,
      // getCustomer call does not require mparticleId
      mparticleId: mparticleId || window.mParticle?.Identity?.getCurrentUser?.().getMPID() || '',
    }),
  };

  return fetch(`${window.origin}${url}`, { ...options })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network response was not ok.');
    })
    .catch((error) => {
      throw new Error(error.message);
    });
}

