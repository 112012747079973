import { createAction } from 'redux-actions';
import api from 'lib/api';
import { hasSingleNav } from 'lib/hasSingleNav';

export const ACTIVATE = 'navbar/ACTIVATE';
export const DEACTIVATE = 'navbar/DEACTIVATE';
export const SET_THEME = 'navbar/SET_THEME';
export const SET_HEADLINE = 'navbar/SET_HEADLINE';
export const SET_SHORTCUT_VISIBLE = 'navbar/SET_SHORTCUT_VISIBLE';
export const BULK_UPDATE = 'navbar/BULK_UPDATE';
export const SET_INITIAL_LOCAL = 'navbar/SET_INITIAL_LOCAL';
export const SET_INITIAL_THEME = 'navbar/SET_INITIAL_THEME';
export const SET_DURATION = 'navbar/SET_DURATION';
const MINI_PLAYER_LOAD_PENDING = 'navbar/MINI_PLAYER_LOAD_PENDING';
const MINI_PLAYER_LOAD_COMPLETE = 'navbar/MINI_PLAYER_LOAD_COMPLETE';
const MINI_PLAYER_LOAD_FAILED = 'navbar/MINI_PLAYER_LOAD_FAILED';

export const activate = createAction(ACTIVATE);
export const deactivate = createAction(DEACTIVATE);
export const setTheme = createAction(SET_THEME);
export const setDuration = createAction(SET_DURATION);
export const setHeadline = createAction(SET_HEADLINE);
export const setShortcutVisible = createAction(SET_SHORTCUT_VISIBLE);
export const bulkUpdate = createAction(BULK_UPDATE);
export const setInitialTheme = createAction(SET_INITIAL_THEME);
export const setInitialNavbarLocal = createAction(SET_INITIAL_LOCAL);

export const getMiniPlayerData = (vertical) => api({
  endpoint: `miniPlayerTease/${vertical}`,
  types: [MINI_PLAYER_LOAD_PENDING, MINI_PLAYER_LOAD_COMPLETE, MINI_PLAYER_LOAD_FAILED],
  api: 'services',
});

// Reducer
const INITIAL_STATE = {
  theme: 'light',
  setDuration: null,
  hasGlobalInsert: false,
  hasLocalInsert: false,
  headline: null,
  active: false,
  shortcutsVisible: true,
  shareUrl: null,
  miniPlayerLoading: false,
  miniPlayerLoaded: false,
  miniplayerError: false,
  miniPlayerData: {
    showTease: false,
  },
};

export const navbar = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case ACTIVATE:
      return {
        ...state,
        active: true,
      };

    case DEACTIVATE:
      return {
        ...state,
        active: false,
      };

    case SET_THEME:
      return {
        ...state,
        theme: payload,
      };

    case SET_DURATION:
      return {
        ...state,
        localAnimationDuration: payload,
      };

    case SET_HEADLINE:
      return {
        ...state,
        headline: payload,
      };

    case SET_SHORTCUT_VISIBLE:
      return {
        ...state,
        shortcutsVisible: payload,
      };

    case BULK_UPDATE:
      return {
        ...state,
        ...payload,
      };

    case SET_INITIAL_LOCAL: {
      // if singleNav, then we have a local insert
      const { vertical, featureFlagQueryParam } = payload;
      const hasLocalInsert = hasSingleNav(vertical, featureFlagQueryParam);
      return {
        ...state,
        hasLocalInsert,
      };
    }

    case SET_INITIAL_THEME: {
      let theme = 'light';
      if (['msnbc', 'noticias', 'deportes'].includes(payload)) {
        theme = 'vertical';
      } else if (payload === 'today') {
        theme = 'transparent';
      }
      return {
        ...state,
        theme,
      };
    }

    case MINI_PLAYER_LOAD_PENDING: {
      return {
        ...state,
        miniPlayerLoading: true,
      };
    }

    case MINI_PLAYER_LOAD_COMPLETE: {
      return {
        ...state,
        miniPlayerLoading: false,
        miniPlayerLoaded: true,
        miniPlayerData: payload.data,
      };
    }

    case MINI_PLAYER_LOAD_FAILED: {
      return {
        ...state,
        miniPlayerLoading: false,
        miniPlayerLoaded: true,
        miniplayerError: true,
      };
    }

    default:
      return state;
  }
};
