/* eslint-disable no-use-before-define */

/**
 * Helper constants and functions
 */
// Utility function to use String.replace preventing type errors
function saferReplace(value, pattern, replacement) {
  if (typeof value === 'string') {
    return value.replace(pattern, replacement);
  }
  return value;
}

/**
 * URL functions
 */
function getCanonical(url) {
  let ret = url;
  if (typeof url === 'object') {
    ret = stripTrailingSlash(url.canonical || url.primary || null);
  } else {
    ret = stripTrailingSlash(url);
  }
  if (ret) {
    ret = ret.toLowerCase();
  }
  if (/http:\/\/[^.]+.(nbcnews|today|msnbc).com\//.test(ret)) {
    return securify(ret);
  }
  return ret;
}

/**
 * @param {VideoAsset[]} videoAssets
 */
function getContentUrl(videoAssets) {
  if (!videoAssets || !videoAssets.length) {
    return null;
  }

  const publicUrl = videoAssets.find((el) => el?.publicUrl)?.publicUrl;

  const {
    origin,
    pathname,
    searchParams,
  } = new URL(publicUrl);

  searchParams.append('format', 'redirect');
  const contentUrl = `${origin}${pathname}?${searchParams.toString()}`;

  return contentUrl;
}

// Basic url validation
function isValidUrl(url) {
  // eslint-disable-next-line max-len
  return /^((https?|ftp|smtp):\/\/)?([^.]+.)?[a-z0-9]+\.[a-z]+(\/*)([a-zA-Z0-9#\-_.]+\/?)*(\?([\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?)?$/
    .test(url);
}

/**
 * Basic url validation. Will only validate absolute URLs (i.e. ones that start
 * with `https://www.domain.com...`), relative URLs will not pass.
 * @param {*} url value to test
 * @returns {boolean}
 */
function isValidAbsoluteUrl(url) {
  try {
    // using `new URL` to verify `url` is a valid URL. we don't actually need to
    // save the parsed URL to a variable though.
    // eslint-disable-next-line no-new
    new URL(url);
    return true;
  } catch (e) {
    // `new URL` throws a TypeError if the url isn't valid
    return false;
  }
}

// Extract core parts of URL, since URL API is not supported in IE 11
function parseOnClient(url) {
  // Must parse on client-side only
  if (!__CLIENT__) return {}; // eslint-disable-line no-undef

  // Could use a RegExp here but there are an incredible amount of edge cases to cover
  // It's best to let the browser handle this directly
  const a = document.createElement('a');
  a.href = url;
  // Path parts from anchor element
  const {
    hash,
    pathname,
    search,
  } = a;

  // Hostname and protocol from window.location
  const {
    hostname,
    protocol,
    port,
  } = window.location;

  return {
    hash,
    hostname,
    pathname,
    port,
    protocol,
    search,
  };
}

// Replace 'http' with 'https'
function securify(url) {
  return saferReplace(url, 'http:', 'https:');
}

// Convert object into query string of ?{key}={value}
function serialize(obj, encode = true) {
  const str = Object.keys(obj)
    .filter((k) => typeof obj[k] !== 'undefined')
    .reduce((a, k) => {
      a.push(`${k}=${encode ? encodeURIComponent(obj[k]) : obj[k]}`);
      return a;
    }, [])
    .join('&');
  return `?${str}`;
}

// String trailing slash on url
function stripTrailingSlash(url) {
  return saferReplace(url, /\/$/, '');
}

// Strip leading slash on url
function stripLeadingSlash(url) {
  return saferReplace(url, /^\//, '');
}

// Strip query params on url
function stripQueryParams(url) {
  return url.split('?')[0];
}

/**
 * The WHATWG URL API is great, but you always have to use a `try`/`catch` block in case the value
 * you're tryin go parse is falsey/unparseable. This function attempts to make that easier while
 * also providing a way for you to modify the parsed URL.
 * @param {string|unknown} maybeUrl URL string or some other value, which will be parsed and passed
 * to `modifyFn`
 * @param {(parsedUrl: URL) => void} [modifyFn] `modifyFn` will be passed the parsed URL instance
 * for modification. no need to return the modified URL, as long as you're modifying properties of
 * the argument passed in (normally you'd avoid modifying properties of an arg passed into a
 * function, but in this case it's ok)
 * @returns {string|unknown}
 * @example
 * const modified = modifyUrl(someUrl, (parsedUrl) => {
 *   parsedUrl.pathname += '/added/path';
 *   parsedUrl.searchParams.set('someParam', 'someVal');
 * })
 */
function modifyIfUrl(maybeUrl, modifyFn = (url) => url) {
  try {
    const parsedUrl = new URL(maybeUrl);
    modifyFn(parsedUrl);
    return parsedUrl.toString();
  } catch (e) {
    return maybeUrl;
  }
}

module.exports = {
  getCanonical,
  isValidUrl,
  isValidAbsoluteUrl,
  parseOnClient,
  securify,
  serialize,
  stripTrailingSlash,
  stripLeadingSlash,
  stripQueryParams,
  getContentUrl,
  modifyIfUrl,
};
