const getConfig = require('next/config').default;

const ELECTIONS_NAV_2022_BASE_URL = getConfig?.()?.publicRuntimeConfig?.ELECTIONS_NAV_2022_BASE_URL
  || 'https://www.nbcnews.com/politics/2022-elections/recirc-nav/embed/borders';
const ELECTIONS_NAV_2024_BASE_URL = getConfig?.()?.publicRuntimeConfig?.ELECTIONS_NAV_2024_BASE_URL
  || 'https://www.nbcnews.com/politics/2024-elections/recirc-nav/embed/borders';

module.exports = {
  AMPHTML_VIDEO_LINK: {
    default: false,
    better: true,
    msnbc: true,
    news: true,
    think: true,
    today: true,
    noticias: true,
    deportes: true,
    localstationdeportes: true,
    entretenimiento: true,
    shows: true,
    telemundo: true,
  },
  RELATED_ARTICLE_BOTTOM_RECIRC: {
    default: false,
    today: true,
  },
  AUTHOR_HUB: {
    default: null,
    msnbc: {
      url: '/opinion/columnists',
      text: 'View All MSNBC Columnists',
    },
  },
  USE_MPX_VIDEO_PARAMS: {
    default: false,
    shows: true,
    noticias: true,
    deportes: true,
    localstationdeportes: true,
    telemundo: true,
    entretenimiento: true,
  },
  LINEAR_VIDEO_BANNER: {
    default: true,
    noticias: false,
  },
  AUTOPLAY_ARTICLE_HERO_VIDEOS: {
    default: false,
    noticias: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    deportes: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    localstationdeportes: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    entretenimiento: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    shows: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    telemundo: {
      autoplay: true,
      chromelessAutoplay: false,
      muted: true,
    },
    news: {
      autoplay: true,
      muted: true,
    },
    today: {
      autoplay: true,
      muted: true,
    },
    better: {
      autoplay: true,
      muted: true,
    },
    think: {
      autoplay: true,
      muted: true,
    },
    mach: {
      autoplay: true,
      muted: true,
    },
    msnbc: {
      autoplay: true,
      muted: true,
    },
    select: {
      autoplay: true,
      muted: true,
    },
  },
  AUTOPLAY_CANONICAL_VIDEOS_MUTED_ON_MOBILE: {
    default: true,
    telemundo: false,
  },
  PRELOAD_FONT_FAMILY: {
    select: ['Lato-Regular.', 'Lato-Bold.', 'Lato-Black.'],
    today: ['Prata-Regular', 'Mada-VariableFont_wght'],
    noticias: [
      'FoundersGroteskMonoWeb-Regular',
      'FoundersGroteskCondensedWeb-Semibold',
      'PublicoHeadline-Light-Web',
      'PublicoText-Roman-Web',
    ],
    default: [
      'FoundersGroteskMonoWeb-Regular',
      'FoundersGroteskCondensedWeb-Semibold',
      'PublicoHeadline-Light-Web',
    ],
  },
  ARTICLE_TAXONOMY_SECTION_NUMBERING: {
    default: false,
    msnbc: ['nbcnews/section/opinion'],
  },
  DEFAULT_LANGUAGE: {
    default: 'en',
    entretenimiento: 'es',
    deportes: 'es',
    localstationdeportes: 'es',
    noticias: 'es',
    shows: 'es',
    telemundo: 'es',
  },
  ENABLE_MINI_TEASE: {
    default: false,
    msnbc: {
      MsnbcTVE: {
        default: 'MsnbcTVE',
        local: 'MsnbcLive',
      },
    },
    news: {
      newsspecialreport: {
        default: 'NewsNow',
        local: 'NewsNow',
      },
      newsnow: {
        default: 'NewsNow',
        local: 'WatchNowTease',
      },
    },
    today: {
      todayallday: {
        default: 'TodayAllDay',
        local: 'TodayLive',
      },
    },
    noticias: {
      noticiasahora: {
        default: 'NoticiasAhora',
        local: 'NoticiasAhora',
      },
    },
  },
  LOCAL_NAV_INSERT_OVERRIDE: {
    default: false,
    noticias: true,
  },
  ENABLE_NEWS_MEDIA_ORG_SCHEMA_TYPE: {
    default: false,
    today: true,
    msnbc: true,
    news: true,
    select: true,
  },
  ENABLE_NEW_AUTHOR_PAGE_SEO: {
    default: false,
    today: true,
    msnbc: true,
    news: true,
  },
  HIDE_TIMESTAMP_FOR_BREAKING_BIG_HEADLINE: {
    default: false,
    msnbc: true,
  },
  LAYOUT_7_LEFT_COLUMNS_AT_LARGE_BREAKPOINT: {
    default: false,
    entretenimiento: true,
    shows: true,
    telemundo: true,
    today: true,
  },
  LD_JSON_RECIPE_CAROUSELS: {
    default: false,
    today: true,
  },
  MOBILE_ACQUISITION_BANNER: {
    default: false,
    today: true,
  },
  MOBILE_PLAYLIST_NUMBER_OF_VIDEOS: {
    default: 6,
    noticias: 20,
    deportes: 20,
    localstationdeportes: 20,
    entretenimiento: 20,
    shows: 20,
    telemundo: 20,
  },
  NL_SIGNUP_BUTTON_ANIMATED: {
    default: true,
    select: false,
  },
  RECOMMENDATIONS_ENABLED: {
    default: true,
    entretenimiento: false,
    noticias: false,
    deportes: false,
    localstationdeportes: false,
    shows: false,
    telemundo: false,
  },
  REMOVE_NOW_PLAYING_VIDEO_FROM_PLAYLIST: {
    default: false,
    entretenimiento: true,
    noticias: true,
    deportes: true,
    localstationdeportes: true,
    shows: true,
    telemundo: true,
  },
  REORDER_PLAYLIST_SO_NEXT_VIDEO_IS_ALWAYS_AT_TOP: {
    default: false,
    entretenimiento: true,
    noticias: true,
    deportes: true,
    localstationdeportes: true,
    shows: true,
    telemundo: true,
  },
  SLIDESHOW_SCROLLABLE_TYPE: {
    default: false,
    eonline: true,
  },
  TEASE_LIST_ALTERNATE_ITEM_DISPLAY: {
    default: false,
    entretenimiento: true,
    shows: true,
    telemundo: true,
    today: true,
  },
  TEASE_LIST_ROUND_IMAGES: {
    default: false,
    entretenimiento: true,
    shows: true,
    telemundo: true,
    today: true,
  },
  HIDE_GLOBAL_MENU_NAV: {
    default: false,
    news: true,
    sponsoredcontent: true,
    today: true,
    msnbc: true,
  },
  UNIVERSAL_CHECKOUT_ENABLED: {
    default: false,
    telemundo: true,
    deportes: true,
    localstationdeportes: true,
    noticias: true,
    shows: true,
    entretenimiento: true,
  },
  UNIVERSAL_CHECKOUT_WELLS_FARGO_BANNER: {
    default: false,
    telemundo: true,
    deportes: true,
    localstationdeportes: true,
    noticias: true,
    shows: true,
    entretenimiento: true,
  },
  SLIDESHOW_TABOOLA_FEED: {
    default: false,
    eonline: true,
  },
  TABOOLA_CUSTOM_PLACEMENT: {
    default: false,
    entretenimiento: true,
  },
  TABOOLA_RECO_REEL: {
    default: false,
    noticias: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
    deportes: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
    localstationdeportes: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
    entretenimiento: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
    shows: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
    telemundo: {
      onlyIfParagraphsCount: 7,
      displayAfter: 5,
      inverted: false,
    },
  },
  RENDER_SLIDESHOW_AS_COLLAGE_ON_COVER: {
    default: true,
    news: false,
  },
  APPLE_ITUNES_APP_ID: {
    default: null,
    telemundo: 680595680,
    deportes: 686218010,
    localstationdeportes: 686218010,
    noticias: 1053483542,
    shows: 680595680,
    entretenimiento: 680595680,
  },
  LIVE_VIDEO_EMBED_LOGO: {
    default: true,
    telemundo: false,
    deportes: false,
    localstationdeportes: false,
    noticias: true,
    shows: false,
    entretenimiento: false,
  },
  MYNEWS_ENABLED: {
    default: false,
    news: {
      accountType: 'mynews',
      organization: 'nbcnews',
    },
    today: {
      accountType: 'todayaccounts',
      organization: 'today',
    },
  },
  RECIRC_SAVE_BUTTON: {
    default: false,
    today: true,
  },
  SHOW_BLOGS: {
    msnbc: {
      '/rachel-maddow-show/maddowblog/': {
        frontRoute: 'maddowblog',
        allPostsLink: 'https://www.msnbc.com/maddowblog',
        allPostsText: 'ALL MADDOWBLOG POSTS',
        ampArticleSubPath: 'shows/maddow/blog/',
      },
      '/the-reidout/reidout-blog/': {
        frontRoute: 'reidoutblog',
        allPostsLink: 'https://www.msnbc.com/reidoutblog',
        allPostsText: 'ALL REIDOUTBLOG POSTS',
        ampArticleSubPath: 'shows/reidout/blog/',
      },
      '/deadline-white-house/deadline-legal-blog/': {
        frontRoute: 'deadline-legal-blog',
        allPostsLink: 'https://www.msnbc.com/deadline-legal-blog',
        allPostsText: 'ALL DEADLINE: LEGAL POSTS',
        ampArticleSubPath: 'shows/deadlinewhitehouse/blog/',
      },
      '/top-stories/latest/': {
        frontRoute: 'top-stories',
        allPostsLink: 'https://www.msnbc.com/top-stories',
        allPostsText: 'LATEST',
        ampArticleSubPath: 'shows/top-stories/blog/',
        pageNavigation: {
          hideTop: true,
        },
      },
    },
    news: {
      '/meet-the-press/meetthepressblog/': {
        frontRoute: 'meetthepressblog',
        allPostsLink: 'https://www.nbcnews.com/meetthepressblog',
        allPostsText: 'LATEST POSTS',
        ampArticleSubPath: 'shows/meetthepress/blog/',
      },
    },
  },
  PROCESS_TELEMUNDO_IMAGE: {
    default: false,
    telemundo: true,
    deportes: true,
    localstationdeportes: true,
    noticias: true,
    shows: true,
    entretenimiento: true,
  },
  ENABLE_ELECTIONS_NAV_THEME: {
    default: false,
    news: {
      yearPosition: 0,
      firecrackerEmbedURL: {
        2020: 'https://www.nbcnews.com/politics/2020-elections/recirc-nav/embed/borders',
        2022: ELECTIONS_NAV_2022_BASE_URL,
        2024: ELECTIONS_NAV_2024_BASE_URL,
      },
    },
    noticias: {
      yearPosition: 3,
      firecrackerEmbedURL: {
        // eslint-disable-next-line max-len
        2022: `${ELECTIONS_NAV_2022_BASE_URL}?brand=telemundo`,
      },
    },
  },
  LIVEBLOG_DEEPLINK: {
    telemundo: 'noticias',
    msnbc: 'msnbc',
    default: 'nbcnews',
  },
  UNIBROW_OVERRIDE: {
    select: {
      link: 'https://www.nbcnews.com/select',
      text: 'Shopping',
    },
    default: false,
  },
  ARTICLE_INLINE_NEWSLETTER: {
    default: true,
    telemundo: false,
    deportes: false,
    localstationdeportes: false,
    noticias: false,
    shows: false,
    entretenimiento: false,
  },
  MID_ARTICLE_NEWSLETTER: {
    default: false,
    news: true,
  },
  AIRSHIP_WEB_NOTIFICATIONS: {
    default: undefined,
    msnbc: {
      all: {
        channelTag: 'msnbc',
        cookie: 'webNotifSoftOptin',
        optOutCountLocalStorageKey: 'AirshipWebNotificationsOptOutCount',
        sdkPath: {
          test: 'https://nodeassets.nbcnews.com/airship/msnbc/airshipSdk-green.js',
          prod: 'https://nodeassets.nbcnews.com/airship/msnbc/airshipSdk-green.js',
        },
        softOptInDisplayDelay: 8000,
        softOptInDisplayInterval: [2, 'week'],
        softOptInDisplayIntervals: [
          {
            optOutCount: 1,
            interval: [2, 'week'],
          },
          {
            optOutCount: 2,
            interval: [2, 'week'],
          },
          {
            optOutCount: 3,
            interval: [2, 'week'],
          },
        ],
        softOptInMaxAskLimit: 4,
      },
    },
    today: {
      shop: {
        channelTag: 'todayshop',
        cookie: 'webNotifSoftOptinTodayShop',
        optOutCountLocalStorageKey:
          'AirshipWebNotificationsOptOutCountTodayShop',
        sdkPath: {
          test: 'https://nodeassets.nbcnews.com/airship/today/test-snippet.js',
          prod: 'https://nodeassets.nbcnews.com/airship/today/production-snippet.js',
        },
        softOptInDisplayDelay: 8000,
        softOptInDisplayInterval: [2, 'week'],
        softOptInDisplayIntervals: [
          {
            optOutCount: 1,
            interval: [2, 'week'],
          },
          {
            optOutCount: 2,
            interval: [2, 'week'],
          },
          {
            optOutCount: 3,
            interval: [2, 'week'],
          },
        ],
        softOptInMaxAskLimit: 4,
      },
    },
  },
  NEWSLETTER_SIGNUP_POPUP_MODULE: {
    default: true,
    msnbc: false,
    today: false,
  },
  MARQUEE_PUBLISHER: {
    telemundo: 'telemundo',
    noticias: 'telemundo',
    deportes: 'telemundo',
    localstationdeportes: 'telemundo',
    entretenimiento: 'telemundo',
    shows: 'telemundo',
    today: 'today',
    msnbc: 'msnbc',
    default: 'nbcnews',
  },
  QUERYLY_SEARCH_METADATA: {
    default: false,
    today: true,
  },
  RUN_EXPERIMENT: {
    // Entrypoint where the <RunExperiment /> component is used
    getBodyItemComponent: {
      // Component name
      NewsletterSignupInline: {
        // Experiment campaign number for each brand
        default: undefined,
        today: 9,
        news: 15,
      },
    },
  },
  CURATOR_AD_PLACEHOLDER: {
    default: false,
    today: true,
  },
  FLUID_WIDTH_FRONT: {
    default: false,
    today: true,
  },
  UP_PKG_PAID_ENABLED: {
    default: true,
    today: false,
    sponsoredcontent: false,
  },
  PKG_SAVE: {
    default: false,
    today: true,
  },
  ENABLE_RETINA: {
    default: false,
    today: true,
    select: true,
  },
  HAS_BACON_VIDEO_PLAYLISTS: {
    default: false,
    today: true,
  },
  HAS_CUSTOM_TITLE: {
    today: true,
    deault: false,
  },
  ARTICLE_HERO_LAYOUT: {
    default: {
      default: true,
    },
    today: {
      wide: true,
    },
  },
  ADDITIONAL_SELLERS: {
    default: false,
    today: true,
    select: true,
  },
  BUTTON_TYPE: {
    default: 'button-hover-animation',
    today: 'button-tilt',
  },
  TXT_IMG_TANDEM_HOVER: {
    default: false,
    today: true,
  },
  EMAIL_PLACEHOLDER_TXT: {
    default: 'Enter your email',
    today: 'Your email address',
  },
  RENDER_SHORT_BIO: {
    default: false,
    news: true,
    msnbc: false,
    today: true,
    select: true,
  },
  SHOW_USER_ACTIONS: {
    default: false,
    news: {
      hideFromChromeless: true,
    },
    today: {
      showBookmark: true,
      showPinterest: true,
    },
  },
  USER_ACTIONS_BELOW_ARTICLE: {
    default: false,
    today: true,
  },
  HAS_SAVED_FILTER: {
    default: false,
    today: true,
  },
  SAVE_BUTTON_STYLE: {
    default: null,
    news: 'withText',
    today: 'contentTypeShape',
  },
  MOBILE_CAROUSEL_DRAWER: {
    default: false,
    today: true,
  },
  GATED_CONTENT: {
    default: false,
    today: true,
  },
  MOST_POPULAR_MODULE_BRAND_CONFIG: {
    default: false,
    news: true,
  },
  IDENTITY_SDK_URL_PATH: {
    default: null,
    news: '/sdk/v7.4.5/identity.min.js',
    today: '/websdk/v2/identity-sdk.min.js',
  },
  SHOW_MOBILE_WEB_NAV: {
    default: false,
    today: true,
    news: true,
  },
  SHOW_2x3_CARD_GRADIENT: {
    default: true,
    today: false,
  },
  SHOW_2x3_BUTTON_CARET: {
    default: false,
    today: true,
  },
  AUTHOR_FEED_UNDERLINE_VARIANT: {
    default: false,
    today: true,
  },
  AUTHOR_FEED_TEASE_VARIANT: {
    default: false,
    today: true,
  },
  SEE_MORE_BUTTON: {
    default: false,
    today: true,
  },
  PRODUCT_WAFFLE_DISCLAIMER_LINK: {
    today:
      'https://www.today.com/shop/everything-you-need-know-about-shop-today-t161374',
    select: 'https://www.nbcnews.com/select/shopping/about-ncna1280612',
    default: '',
  },
  UPDATED_CANONICAL_VIDEO: {
    default: false,
    today: true,
  },
  CANONICAL_VIDEO_SHARE_THIS: {
    default: false,
    msnbc: true,
    noticias: true,
    deportes: true,
    localstationdeportes: true,
    entretenimiento: true,
    shows: true,
    telemundo: true,
  },
  CANONICAL_VIDEO_SAVE: {
    default: true,
    today: false,
  },
  ARTICLE_PINTEREST: {
    default: false,
    today: true,
  },
};
