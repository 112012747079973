import PropTypes from 'prop-types';
import content from './content';

export default PropTypes.shape({
  autofill: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  }),
  id: PropTypes.string,
  items: PropTypes.arrayOf(content),
  metadata: PropTypes.objectOf(PropTypes.any),
  name: PropTypes.string,
  subType: PropTypes.string,
  type: PropTypes.string,
  zone: PropTypes.number,
});
